















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ITemplate from '../models/ITemplate'
const { mapGetters } = createNamespacedHelpers('Templates')

export default Vue.extend({
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: 'Template'
    },
    disabled: Boolean,
    filter: {
      type: Function,
      default() {
        return true
      }
    }
  },
  computed: {
    ...mapGetters(['results']),
    items(): ITemplate[] {
      const items = this.results.filter(this.filter) as ITemplate[]
      return items
    },
    tooltip(): string {
      const templates = this.results as ITemplate[]
      const selectedTemplate = templates.find((template) => template.id === this.value)
      return selectedTemplate?.name
    }
  }
})
